<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/develop/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>  
        <div style="margin-top: 50px;">
            <el-row>
                <el-col :span="4" :lg="0">
                    <br/>
                </el-col>
                <el-col :span="20" :lg="24">
                    <div style="height: 300px;background: #F5F5F5;padding-top: 30px;">
                        <el-row>
                            <el-col :span="9" :lg="11" style="margin-left: -30px;">
                                <el-image style="height: 300px;" :src="require('@/assets/develop/kehu.jpg')"></el-image>
                            </el-col>
                            <el-col :span="11" :lg="13">
                                <div style="margin-top: 15px;font-size: 24px;font-weight: bold;">{{$t('develop.kehutitle')}}</div>
                                <div style="font-size: 16px;color: #333333;line-height: 32px;margin-top: 15px;">{{$t('develop.kehu1')}}</div>
                                <div style="font-size: 16px;color: #333333;line-height: 32px;">{{$t('develop.kehu2')}}</div>
                                <div style="font-size: 16px;color: #333333;line-height: 32px;">{{$t('develop.kehu3')}}</div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row style="padding-top: 50px;">
                <el-col :span="20" :lg="24">
                    <div style="height: 300px;background: #F5F5F5;padding-top: 30px;">
                        <el-row>
                            <el-col :span="4" :lg="0">
                                <br/>
                            </el-col>
                            <el-col :span="11" :lg="13">
                                <div style="padding-left: 25px;">
                                    <div style="margin-top: 15px;font-size: 24px;font-weight: bold;">{{$t('develop.gongyingshangtitle')}}</div>
                                    <div style="font-size: 16px;color: #333333;line-height: 35px;margin-top: 15px;">{{$t('develop.gongyingshang1')}}</div>
                                </div>
                            </el-col>
                            <el-col :span="9" :lg="11" style="display: flex; justify-content: flex-end;">
                                <el-image style="height: 300px;" :src="require('@/assets/develop/gongyingshang.jpg')"></el-image>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row style="padding-top: 80px;">
                <el-col :span="4" :lg="0">
                    <br/>
                </el-col>
                <el-col :span="20" :lg="24">
                    <div style="height: 300px;background: #F5F5F5;padding-top: 30px;">
                        <el-row>
                            <el-col :span="9" :lg="11" style="margin-left: -30px;">
                                <el-image style="height: 300px;" :src="require('@/assets/develop/yuangong.jpg')"></el-image>
                            </el-col>
                            <el-col :span="11" :lg="13">
                                <div style="margin-top: 15px;font-size: 24px;font-weight: bold;">{{$t('develop.yuangongtitle')}}</div>
                                <div style="font-size: 16px;color: #333333;line-height: 35px;margin-top: 15px;">{{$t('develop.yuangong1')}}</div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row style="padding-top: 50px;">
                <el-col :span="20" :lg="24">
                    <div style="height: 300px;background: #F5F5F5;padding-top: 30px;">
                        <el-row>
                            <el-col :span="4" :lg="0">
                                <br/>
                            </el-col>
                            <el-col :span="11" :lg="13">
                                <div style="padding-left: 25px;">
                                    <div style="margin-top: 15px;font-size: 24px;font-weight: bold;">{{$t('develop.shequtitle')}}</div>
                                    <div style="font-size: 16px;color: #333333;line-height: 35px;margin-top: 15px;">{{$t('develop.shequ1')}}</div>
                                    <div style="font-size: 16px;color: #333333;line-height: 35px;margin-top: 5px;">{{$t('develop.shequ2')}}</div>
                                </div>
                            </el-col>
                            <el-col :span="9" :lg="11" style="display: flex; justify-content: flex-end;">
                                <el-image style="height: 300px;" :src="require('@/assets/develop/shequ.jpg')"></el-image>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row style="padding-top: 80px;">
                <el-col :span="4" :lg="0">
                    <br/>
                </el-col>
                <el-col :span="20" :lg="24">
                    <div style="height: 300px;background: #F5F5F5;padding-top: 30px;">
                        <el-row>
                            <el-col :span="9" :lg="11" style="margin-left: -30px;">
                                <el-image style="height: 300px;" :src="require('@/assets/develop/huanjing.jpg')"></el-image>
                            </el-col>
                            <el-col :span="11" :lg="13">
                                <div style="margin-top: 15px;font-size: 24px;font-weight: bold;">{{$t('develop.huanjingtitle')}}</div>
                                <div style="font-size: 16px;color: #333333;line-height: 35px;margin-top: 15px;">{{$t('develop.huanjing1')}}</div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
}
</script>